@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: ClashDisplay, sans-serif;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'ClashDisplay';
  src: url('../src/Fonts/ClashDisplay-Regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'ClashDisplay';
  src: url('../src/Fonts/ClashDisplay-Medium.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'ClashDisplay';
  src: url('../src/Fonts/ClashDisplay-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'ClashDisplay';
  src: url('../src/Fonts/ClashDisplay-Bold.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

.clash {
  font-family: ClashDisplay, sans-serif;
  font-style: normal;
  font-display: swap;
}

.numberShadow {
  text-shadow: 0px 4px 4px #6260E1;
  font-family: ClashDisplay, sans-serif;
}

.readyOverflow {
  overflow-x: hidden ;
  overflow-y: scroll;
}

@media (min-width: 540px) {
  .readyOverflow {
    overflow-x: inherit ;
  }
}